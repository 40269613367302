import { OwcCard, OwcIcon, OwcTypography } from "@one/react";
import React, { useContext } from "react";
import styles from "./ContactInfo.module.scss";
import SyncPhoneNo from "./SyncPhoneNo";
import { compose } from "redux";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { displayNameSelector } from "../../user/redux/selectors";
import { SubscriptionSettingsContext } from "../context/context";
import {
  CHANNEL_TYPES,
  CONTACT_INFO_MESSAGE_PHONE_NUMBER
} from "../../../constants";

const ContactInfo = ({ email }) => {
  const { changes, emailConfirmed } = useContext(SubscriptionSettingsContext);

  const shouldDisplay =
    !emailConfirmed &&
    changes.some(
      (sub) => sub?.channel?.trim()?.toUpperCase() === CHANNEL_TYPES.EMAIL.value
    );
  return (
    <div>
      <OwcCard className={styles.notificationmainContactInfo}>
        <div slot="header">
          <OwcTypography variant="button">Contact information</OwcTypography>
        </div>
        <div className={styles.contactinfoContainer}>
          <SyncPhoneNo />
          <div className={styles.contactinfoEmail}>
            <OwcIcon name="mail" type="legacy" style={{ marginRight: 10 }} />
            <OwcTypography variant="button">{email}</OwcTypography>
          </div>
          <div className={styles.contactinfoEmail}>
            {!shouldDisplay ? (
              <>
                <OwcIcon
                  name="circle_info"
                  type="legacy"
                  style={{ marginRight: 10 }}
                />
                <OwcTypography variant="button">
                  {CONTACT_INFO_MESSAGE_PHONE_NUMBER}
                </OwcTypography>
              </>
            ) : (
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <OwcIcon
                  name="alarm"
                  type="legacy"
                  style={{
                    color: "var(--one-color-background-warning-1)",
                    marginRight: 10
                  }}
                />
                <OwcTypography variant="button">
                  Don't want to miss notification? <br />
                  Go to your email confirm the subscription
                </OwcTypography>
              </div>
            )}
          </div>
        </div>
      </OwcCard>
    </div>
  );
};

const mapStateToProps = (store) => ({
  name: displayNameSelector(store),
  email: store.user.email
});

export default compose(connect(mapStateToProps), withApollo)(ContactInfo);
