const IconForApp = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5 19.083C14.167 20.083 13.167 20.75 12 20.75C10.833 20.75 9.833 20.083 9.5 19.083H14.5Z"
      fill="#0B41CD"
    />
    <path
      d="M17.833 16.583V10.75C17.833 8.083 16.083 5.917 13.667 5.167V4.917C13.667 4 12.917 3.25 12 3.25C11.083 3.25 10.333 4 10.333 4.917V5.167C7.917 5.917 6.167 8.083 6.167 10.75L6.167 16.583H4.5V18.25H19.5V16.583H17.833ZM16.167 16.583H7.833L7.833 10.75C7.82752 10.2013 7.93156 9.65694 8.13903 9.1489C8.3465 8.64086 8.65323 8.17931 9.04127 7.79127C9.42931 7.40323 9.89086 7.0965 10.3989 6.88903C10.9069 6.68156 11.4513 6.57752 12 6.583C12.5487 6.57752 13.0931 6.68156 13.6011 6.88903C14.1091 7.0965 14.5707 7.40323 14.9587 7.79127C15.3468 8.17931 15.6535 8.64086 15.861 9.1489C16.0684 9.65694 16.1725 10.2013 16.167 10.75V16.583Z"
      fill="#0B41CD"
    />
  </svg>
);
export default IconForApp;
