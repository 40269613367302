import { OwcCard, OwcTypography } from "@one/react";
import React from "react";
import { CHANNEL_TYPES } from "../../../constants";
import styles from "./Card.module.scss";
import {
  SeveritySelectorConnected,
  SeverityToggler
} from "../components/SeverityCardComponents";

const NotificationCard = ({ data }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {data.map((item) => {
        return (
          <OwcCard className={styles.card}>
            <div slot="header">
              <OwcTypography variant="button">{item.displayName}</OwcTypography>
            </div>
            <div className={styles.cardContainer}>
              {/* toggle */}
              <div className={styles.cardToggleContainer}>
                <OwcTypography variant="body1">
                  {CHANNEL_TYPES.LandingPage.label}
                </OwcTypography>
                <SeverityToggler
                  item={item}
                  field={CHANNEL_TYPES.LandingPage.value}
                />
              </div>
              <div className={styles.cardEmailSMS}>
                <OwcTypography> {CHANNEL_TYPES.EMAIL.label}</OwcTypography>
                <SeveritySelectorConnected
                  item={item}
                  field={CHANNEL_TYPES.EMAIL.value}
                />
              </div>
              <div className={styles.cardEmailSMSRow}>
                <OwcTypography>{CHANNEL_TYPES.SMS.label}</OwcTypography>
                <SeveritySelectorConnected
                  item={item}
                  field={CHANNEL_TYPES.SMS.value}
                />
              </div>
            </div>
          </OwcCard>
        );
      })}
    </div>
  );
};

export default NotificationCard;
