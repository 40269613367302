import React from "react";
import ReactDOM from "react-dom";
import { Rehydrated } from "aws-appsync-react";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";
import { appSyncClient } from "./appSyncClient";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import awsconfig from "./aws-exports";
import Amplify from "aws-amplify";
import Main from "./Main";
import { store } from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import BackendInfoWrapper from "./features/backend-info-context/BackendInfoWrapper";
import HeaderSection from "./features/unique-env-info/HeaderSection";
import FooterSection from "./features/unique-env-info/FooterSection";

Amplify.configure(awsconfig);
process.env.NODE_ENV === "development" && (Amplify.Logger.LOG_LEVEL = "DEBUG");

process.env.NODE_ENV === "development" && (window.store = store);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={appSyncClient}>
      <Provider store={store}>
        <Rehydrated>
          <Router>
            <BackendInfoWrapper>
              <HeaderSection />
              <Main></Main>
              <FooterSection />
            </BackendInfoWrapper>
          </Router>
        </Rehydrated>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
