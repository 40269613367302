import React, { useState } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { compose } from "redux";
import { omit } from "underscore";
import ConfirmDialog from "../../../components/shared/ConfirmDialog";
import {
  UPDATE_PHONE_NUMBER,
  UPDATE_NOTIFICATION_USER_PROFILE
} from "../../../gql/notifications/mutations";
import { updateUserPhoneNumber as updateUserPhoneNumberAction } from "../../user/redux/actions";
import { OwcProgressSpinner } from "@one/react/Components/OwcProgressSpinner";
import { OwcIcon, OwcTypography } from "@one/react";
import { snackbarService } from "@one/web-components";
import { CONTENT_MESSAGE_WITH_ICON, DURATION } from "../../../constants";

const updatePhone = async ({
  client,
  mobilePhone,
  onSuccess,
  onFailed,
  email
}) => {
  try {
    const {
      data: { updatePhoneNumber }
    } = await client.mutate({
      mutation: UPDATE_PHONE_NUMBER,
      fetchPolicy: "no-cache"
    });
    if (!updatePhoneNumber) {
      return onFailed(`Can’t fetch the phone number. Please try again.`);
    }
    if (mobilePhone === updatePhoneNumber?.mobilePhone) {
      return onSuccess(null, `Your phone number has been synced successfully`);
    }
    if (updatePhoneNumber) {
      const {
        data: { updateDigitalLabNotificationsUserProfile }
      } = await client.mutate({
        mutation: UPDATE_NOTIFICATION_USER_PROFILE,
        fetchPolicy: "no-cache",
        variables: {
          input: {
            ...omit(updatePhoneNumber, ["__typename"])
          }
        }
      });
      if (
        updateDigitalLabNotificationsUserProfile?.mobilePhone ===
        updatePhoneNumber?.mobilePhone
      ) {
        onSuccess(updateDigitalLabNotificationsUserProfile);
      } else {
        onFailed("Cannot update phone number, please try again later.");
      }
    }
  } catch (err) {
    onFailed("Cannot fetch phone number, please try again later.");
  }
};
const SyncPhoneNo = ({ client, updateUserPhoneNumber, mobilePhone }) => {
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const onUpdateSuccess = (
    fetchedUser,
    text = `Your phone number has been synced successfully!`
  ) => {
    if (fetchedUser) {
      updateUserPhoneNumber(fetchedUser);
    }
    snackbarService.show({
      message: text,
      type: "success",
      duration: DURATION
    });
    onCancel();
  };
  const onUpdateFailed = (
    text = "Cannot update phone number, please try again later."
  ) => {
    snackbarService.show({
      message: text,
      type: "warning",
      duration: DURATION
    });
    onCancel();
  };
  const onCancel = () => {
    setOpen(false);
  };

  const onApprove = async () => {
    setFetching(true);
    await updatePhone({
      client,
      mobilePhone,
      onSuccess: onUpdateSuccess,
      onFailed: onUpdateFailed
    });
    setFetching(false);
  };

  return (
    <>
      <div
        data-testid="sync-phone-number"
        style={{ display: "flex", alignItems: "center" }}
      >
        <OwcIcon name="call" type="legacy" style={{ marginRight: 10 }} />
        <OwcTypography variant="button">{`${
          mobilePhone || "N/A"
        }`}</OwcTypography>
      </div>
      <div data-testid="sync-phone-number-container">
        {fetching ? (
          <OwcProgressSpinner
            diameter={14}
            data-testid="sync-phone-number-loading"
            style={{ margin: "9px 10px" }}
          />
        ) : (
          <OwcTypography
            variant="button"
            style={{
              color: "var(--one-color-background-brand-1)",
              cursor: "pointer"
            }}
            data-testid="sync-phone-number-action"
            onClick={() => setOpen(true)}
          >
            Sync phone number
          </OwcTypography>
        )}
      </div>
      <ConfirmDialog
        testid="confirm-change-phone-number"
        approveText="Sync phone number"
        approveColor="primary"
        approveVariant="contained"
        cancelText="Cancel"
        cancelVariant="outlined"
        cancelColor="primary"
        title="Are you sure to sync your phone number?"
        content={CONTENT_MESSAGE_WITH_ICON}
        close={() => false}
        onApprove={onApprove}
        loading={fetching}
        open={open}
        onCancel={onCancel}
      />
    </>
  );
};

export default compose(
  connect(
    (store) => ({
      mobilePhone: store.user.mobilePhone
    }),
    {
      updateUserPhoneNumber: updateUserPhoneNumberAction
    }
  ),
  withApollo
)(SyncPhoneNo);
