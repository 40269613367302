import React, { useEffect, useState } from "react";
import "./App.scss";

import CssBaseline from "@mui/material/CssBaseline";
import { Route, Switch } from "react-router-dom";
import AppBar from "./views/AppBar";
import { StyledToastContainer } from "./features/notifications-compontent/Notify";
import "react-toastify/dist/ReactToastify.css";
import LoadNotificationInfo from "./features/notification-app/LoadNotificationInfo";
import NotificationSettings from "./features/notification-settings/MainPage";
import styled from "styled-components";
import { getEnv } from "./utils/helpers/text";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { LIST_BACKEND_HEALTH_CHECKS } from "../src/gql/notifications/queries";
import { API } from "aws-amplify";
import { AwsRum } from "aws-rum-web";
const currentEnv = getEnv();

/**
 * Code for AWS cloud watch RUM
 */
let awsRum = null; //very important otherwise variable is local to try/catch block and will be garbage collected, and possible even removed during compilation (production optimized build)
try {
  let app_telemetries =
    currentEnv === "dev" || currentEnv === "test"
      ? ["performance", "errors", "http"]
      : ["errors"];
  // help for config: https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md
  awsRum = new AwsRum(
    process.env.REACT_APP_AWS_RUM_APPLICATION_ID,
    process.env.REACT_APP_AWS_RUM_APPLICATION_VERSION,
    process.env.REACT_APP_AWS_RUM_APPLICATION_REGION,
    {
      sessionSampleRate: 1, //record 100% of sessions
      sessionEventLimit: 0, //no limit on number of events per session
      guestRoleArn: process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
      identityPoolId: process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
      endpoint: process.env.REACT_APP_AWS_RUM_ENDPOINT,
      telemetries: app_telemetries,
      // in development, record all telemetries
      // telemetries: ["errors"], // for production/staging, only errors
      allowCookies: true,
      enableXRay: false
    }
  );
  // console.log(awsRum);
  //https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM-custom-events.html
  //Caution if you send empty data, you get a strange error:  1 validation error detected: Value null at 'rumEvents.3.member.details' failed to satisfy constraint: Member must not be null
  // Note: custom events must be enabled in the cloudwatch console, otherwise they are simply ignore.
  awsRum.recordEvent("my_custom_event", { value_1: "App Loaded" }); //record custom event (remove later)
} catch (error) {
  console.log(error);
  // Ignore errors thrown during CloudWatch RUM web client initialization
}
// Code for AWS cloud watch RUM ENDS

const AdaptarWrapperStyle = styled.div`
  width: 100%;
  overflow: auto;
`;
const AppVal = styled.div`
  .MuiAppBar-positionAbsolute {
  }
`;
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("recordingError: " + error);
    awsRum.record(error);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <button
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Clear Error
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

const getContainerHeight = (currentEnv) => {
  return currentEnv ? window.innerHeight - 125 : window.innerHeight - 75;
};
function App() {
  const [enableVal, setEnableVal] = useState(false);
  const [containerHeight, setContainerHeight] = useState(
    getContainerHeight(currentEnv)
  );
  window.addEventListener("resize", function () {
    setContainerHeight(getContainerHeight(currentEnv));
  });
  const getBackendAuthChecks = async () => {
    try {
      const backendBuildInfo = await API.graphql({
        query: LIST_BACKEND_HEALTH_CHECKS
      });
      const items = backendBuildInfo?.data?.listBackendHealthChecks?.items;
      const trackDisable = items.map((item) => item?.enableAnalyticsTracking);
      setEnableVal(trackDisable[0]);
    } catch (error) {
      console.log("Matomo api error..", error);
    }
  };
  useEffect(() => {
    getBackendAuthChecks();
  }, []);

  // Matomo code added for analytics
  //urlBase: optional, default value: `${urlBase}matomo.php`
  // siteId : optional, default value: `${urlBase}matomo.js`
  // disabled :optional, false by default. Makes all tracking calls no-ops if set to true.
  // linkTracking :optional, default value: true
  // active: optional, default value: true
  // seconds: optional, default value: `15
  const instance = createInstance({
    urlBase: process.env.REACT_APP_MATOMO_BASE_URL,
    siteId: process.env.REACT_APP_SITE_ID,
    trackerUrl: process.env.REACT_APP_MATOMO_BASE_URL + "matomo.php",
    srcUrl: process.env.REACT_APP_MATOMO_BASE_URL + "matomo.js",
    disabled: enableVal,
    linkTracking: true,
    trackPageView: true,
    heartBeat: {
      active: true,
      seconds: 10
    }
  });
  return (
    <ErrorBoundary>
      <MatomoProvider value={instance}>
        <LoadNotificationInfo>
          <CssBaseline />
          {currentEnv ? (
            <AppVal>
              <AppBar />
            </AppVal>
          ) : (
            <AppBar />
          )}
          <AdaptarWrapperStyle style={{ height: `${containerHeight}px` }}>
            <Switch>
              <Route exact path="/">
                <NotificationSettings />
              </Route>
            </Switch>
          </AdaptarWrapperStyle>
          <StyledToastContainer />
        </LoadNotificationInfo>
      </MatomoProvider>
    </ErrorBoundary>
  );
}

export default App;
