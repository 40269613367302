import { Component } from "react";
import { Auth } from "aws-amplify";
import { OwcProgressSpinner } from "@one/react/Components/OwcProgressSpinner";
import MainPageNotAuthenticated from "../views/MainPageNotAuthenticated";
import { FullScreenCentered } from "./notification-app/LoadNotificationInfo";

class Authentication extends Component {
  constructor(props) {
    super(props);
    this.getUser = this.getUser.bind(this);
  }
  state = {
    user: null,
    loading: true
  };

  getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => this.setState({ user: userData }))
      .catch(() => console.log("Not signed in"))
      .finally(() => this.setState({ loading: false }));
  }
  componentDidMount() {
    this.getUser();
  }
  render() {
    if (this.state.loading) {
      return (
        <FullScreenCentered data-testid="loader">
          <OwcProgressSpinner diameter={80} />
        </FullScreenCentered>
      );
    }
    if (this.state.user === null) {
      return <MainPageNotAuthenticated />;
    }
    return this.props.children;
  }
}

export default Authentication;
