import { createSelector } from "reselect";
import { emailToName } from "../../../utils/helpers/text";

export const getUser = (state) => state.user;

const getName = ({ givenName, familyName }) => {
  const username = `${givenName || ""} ${familyName || ""}`;
  return username.trim();
};

export const displayNameSelector = createSelector(
  [getUser],
  (user) => getName(user) || user?.name || emailToName(user?.email) || "-"
);
