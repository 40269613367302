import { useCallback, useEffect } from "react";

export const useOnLeave = ({ shouldConfirm = false }) => {
  const confirmLeave = useCallback(
    (event) => {
      event?.preventDefault();
      // Chrome requires returnValue to be set.
      if (shouldConfirm) {
        if ("returnValue" in event) {
          event.returnValue = "";
        }
        return "";
      }
      return null;
    },
    [shouldConfirm]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", confirmLeave);
    return () => {
      window.removeEventListener("beforeunload", confirmLeave);
    };
  }, [confirmLeave]);
};
