import React from "react";
import { MainContainer } from "../../components/shared";
import NotificationSubscriptionsList from "./table/NotificationSubscriptionsList";
// import SubscriptionActions from "./components/SubscriptionActions";
import SubscriptionSettingsWrapper from "./context/SubscriptionSettingsWrapper";
import styled from "styled-components";
import { OwcCard, OwcTypography } from "@one/react";
import CardActionButton from "./components/CardActionButton";
import styles from "./NotificationMainPage.module.scss";
const SubscriptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
`;

const MainPage = () => {
  return (
    <MainContainer data-testid="notification-settings">
      <SubscriptionSettingsWrapper>
        <OwcCard className={styles.notificationmainpageCardContainer}>
          <SubscriptionHeader slot="header">
            <OwcTypography variant="subtitle1">
              Notification settings
            </OwcTypography>
          </SubscriptionHeader>
          <NotificationSubscriptionsList />
        </OwcCard>
        <CardActionButton />
      </SubscriptionSettingsWrapper>
    </MainContainer>
  );
};

export default MainPage;
