import * as types from "./actionTypes";

export const loadUserInfo = (userInfo) => ({
  type: types.LOAD_USER_INFO,
  payload: userInfo
});

export const updateUserPhoneNumber = (userInfo) => ({
  type: types.UPDATE_USER_MOBILE_PHONE,
  payload: userInfo
});
