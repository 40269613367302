import React from "react";
import { OwcTypography } from "@one/react/Components/OwcTypography";
import styles from "./WelcomeScreen.module.scss";

const WelcomeScreen = ({ text = "You must login to view instruments" }) => {
  return (
    <div
      className={styles.welcomeScreenWrapper}
      data-testid="main-page-not-authenticated-with-no-error-by-backend"
    >
      <OwcTypography style={{ color: "inherit" }} element="h2" variant="title2">
        Notification
      </OwcTypography>
      <OwcTypography style={{ color: "inherit" }} element="h2" variant="title2">
        Welcome
      </OwcTypography>
      <div className={styles.wrapTitle5}>
        <OwcTypography
          style={{ color: "inherit" }}
          element="h5"
          variant="title5"
        >
          {text}
        </OwcTypography>
      </div>
    </div>
  );
};

export default WelcomeScreen;
