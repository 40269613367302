import gql from "graphql-tag";

export const CREATE_DIGITAL_LAB_NOTIFICATION_USER_PROFILE = gql`
  mutation createDigitalLabNotificationsUserProfile(
    $email: String!
    $site: String
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
  ) {
    createDigitalLabNotificationsUserProfile(
      input: {
        email: $email
        site: $site
        familyName: $familyName
        givenName: $givenName
        name: $name
        phone: $phone
      }
    ) {
      id
      email
      site
      familyName
      givenName
      name
      user
      phone
      site
    }
  }
`;

export const UPDATE_DIGITAL_LAB_NOTIFICATION_USER_PROFILE_PERSONAL_FIELDS = gql`
  mutation updateDigitalLabNotificationsUserProfile(
    $id: ID!
    $email: String!
    $site: String
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
  ) {
    updateDigitalLabNotificationsUserProfile(
      input: {
        id: $id
        email: $email
        familyName: $familyName
        givenName: $givenName
        name: $name
        phone: $phone
        site: $site
      }
    ) {
      id
      email
      site
      familyName
      givenName
      name
      user
      phone
      site
    }
  }
`;

export const CONFIGURE_NOTIFICATION_THRESHOLDS = gql`
  mutation configureNotificationThresholds(
    $arrayOfNotification: [NotificationThresholdInput!]!
  ) {
    configureNotificationThresholds(input: $arrayOfNotification)
  }
`;

export const UPDATE_PHONE_NUMBER = gql`
  mutation updatePhoneNumber {
    updatePhoneNumber {
      id
      email
      mobilePhone
    }
  }
`;

export const UPDATE_NOTIFICATION_USER_PROFILE = gql`
  mutation updateDigitalLabNotificationsUserProfile(
    $input: UpdateDigitalLabNotificationsUserProfileInput!
  ) {
    updateDigitalLabNotificationsUserProfile(input: $input) {
      id
      email
      mobilePhone
    }
  }
`;