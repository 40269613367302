import React from "react";
import ContactInfo from "./contact/ContactInfo";
// import Card from "./card/Card";
import styles from "./NotificationMainPage.module.scss";
import NotificationCard from "./card/Card";
const NotificationMainPage = ({ data = [] }) => {
  return (
    <div className={styles.notificationmainpageWidth}>
      <ContactInfo />
      <NotificationCard data={data} />
    </div>
  );
};

export default NotificationMainPage;
