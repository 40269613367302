import React from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import App from "./App";
import Authentication from "./features/Authentication";
import { Route, Switch } from "react-router-dom";
import NotFoundScreen from "./components/NotFoundScreen";
import { federated } from "./aws-exports";
import ModuleVersion from "./features/system-version/ModuleVersion";
import { OwcThemeProvider } from "@one/react";
// import { setTheme } from "@one/web-components";
/**
 * to turn off google login button - remove federated authenticatorProps below
 */
const AuthenticatedApp = withAuthenticator(App, { federated });

// setTheme("legacy_light"); // Either use setTheme OR OwcThemeProvider
const Main = () => (
  <OwcThemeProvider theme="cobas_light">
    <Switch>
      <Route exact path={["/", "/settings"]}>
        <Authentication>
          <AuthenticatedApp />
        </Authentication>
      </Route>
      <Route exact path="/info">
        <ModuleVersion />
      </Route>
      <Route path="*">
        <NotFoundScreen redirectPath={"/"} />
      </Route>
    </Switch>
  </OwcThemeProvider>
);
export default Main;
