import { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CHANNEL_TYPES, SEVERITY_THRESHOLDS } from "../../../constants";
import {
  LIST_APPLICATION_SOURCES,
  LIST_NOTIFICATIONS_SOURCE_TYPE_BY_USER_CHANNEL
} from "../../../gql/notifications/queries";
import { getAllData } from "../../../utils/helpers/fetching";
import { SubscriptionSettingsContext } from "../context/context";

export const useSubscriptionData = ({ client }) => {
  const [refresh, setRefresh] = useState(1);
  const [serverResponse, setServerResponse] = useState({
    loading: true,
    error: null
  });
  const { email, mobilePhone: phone } = useSelector((store) => store.user);
  const { dispatchAction } = useContext(SubscriptionSettingsContext);

  useEffect(() => {
    const loadData = async () => {
      setServerResponse({
        loading: true,
        error: null
      });
      const { error: errorApp, items: apps } = await getAllData({
        client,
        query: LIST_APPLICATION_SOURCES,
        dataPath: ["data", "listDigitalLabNotificationsSources"],
        drillData: true
      });
      const { error: errorSubs, items: userSubscriptions } = await getAllData({
        client,
        query: LIST_NOTIFICATIONS_SOURCE_TYPE_BY_USER_CHANNEL,
        variables: {
          limit: 2000
        },
        dataPath: [
          "data",
          "listDigitalLabNotificationsSourceTypeFilterForUserChannels"
        ],
        drillData: true
      });
      if (errorApp || errorSubs) {
        return setServerResponse({
          loading: false,
          error: errorApp || errorSubs
        });
      }
      dispatchAction({
        type: "setInitialValues",
        payload: {
          apps,
          initialValues: userSubscriptions
        }
      });
      dispatchAction({
        type: "setDefault",
        payload: {
          key: CHANNEL_TYPES.LandingPage.value,
          states: [SEVERITY_THRESHOLDS.NOT_DEFINED.value],
          fieldName: "severityThreshold",
          defaultValue: SEVERITY_THRESHOLDS.LOW.value
        }
      });
      if (!phone) {
        dispatchAction({
          type: "setDefault",
          payload: {
            key: CHANNEL_TYPES.SMS.value,
            states: [
              SEVERITY_THRESHOLDS.LOW.value,
              SEVERITY_THRESHOLDS.MEDIUM.value,
              SEVERITY_THRESHOLDS.HIGH.value
            ],
            fieldName: "severityThreshold",
            defaultValue: SEVERITY_THRESHOLDS.DISABLED.value
          }
        });
      }
      setServerResponse({
        loading: false,
        error: null
      });
    };
    loadData();
    if (email) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, email, refresh]);

  const refreshData = useCallback(() => {
    setRefresh((prev) => prev + 1);
  }, []);
  return { ...serverResponse, refreshData };
};
