import { useState, useEffect } from "react";
import { BackendInfoContext } from "./context";
import { LIST_BACKEND_HEALTH_CHECKS } from "../../gql/notifications/queries";
import { Amplify, API } from "aws-amplify";
import { getAllData } from "../../utils/helpers/fetching";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { changeDateFormat, getHashCommitValue } from "../../utils/helpers/text";
import { scrollAvailability } from "../../utils/helpers/text";
import { getUser } from "../../utils/hooks/useAuthenticatedUser";
const BackendInfoWrapper = ({ children, client }) => {
  const [backendInfo, setBackendInfo] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const scrollAvailabilityValue = scrollAvailability();
  const frontendModuleVersion = process.env.REACT_APP_MODULE_VERSION
    ? process.env.REACT_APP_MODULE_VERSION
    : "N/A";

  const frontendUpdatedAt = process.env?.REACT_APP_UPDATED_AT
    ? getHashCommitValue(process.env?.REACT_APP_UPDATED_AT)
    : "N/A";

  const frontendCommitHash = process.env.REACT_APP_COMMIT_HASH
    ? getHashCommitValue(process.env.REACT_APP_COMMIT_HASH)
    : "frontendCommit";
  const getBackendUnauthChecks = async () => {
    try {
      const myAppConfig = {
        aws_appsync_graphqlEndpoint:
          process.env.REACT_APP_GRAPHQL_ENDPOINT_HEALTH_CHECK,
        aws_appsync_region: process.env.REACT_APP_AWS_PROJECT_REGION,
        aws_appsync_authenticationType: "AWS_IAM",
        Auth: {
          identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
          region: process.env.REACT_APP_AWS_PROJECT_REGION
        }
      };
      Amplify.configure(myAppConfig);
      const backendBuildInfo = await API.graphql({
        query: LIST_BACKEND_HEALTH_CHECKS
      });
      const items = backendBuildInfo?.data?.listBackendHealthChecks?.items[0];
      setBackendInfo(items);
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const getBackendAuthChecks = async ({ client }) => {
    try {
      const { items: listBackendHealthChecks } = await getAllData({
        client,
        query: LIST_BACKEND_HEALTH_CHECKS,
        fetchPolicy: "no-cache",
        dataPath: ["data", "listBackendHealthChecks"],
        variables: {
          limit: 500
        },
        drillData: true
      });

      setBackendInfo(listBackendHealthChecks[0]);
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  useEffect(() => {
    const getInfoDetails = async () => {
      const currentUser = await getUser();
      if (!currentUser) getBackendUnauthChecks();
      else getBackendAuthChecks({ client });

      setCurrentUser(currentUser);
    };
    getInfoDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const rightLabel = backendInfo?.moduleVersion
    ? `version ${backendInfo?.moduleVersion} updated at ${changeDateFormat(
        backendInfo?.updatedAt,
        "mm/dd/yyyy h:mm:ss"
      )}`
    : `version ${frontendModuleVersion} updated at ${frontendUpdatedAt}`;

  const FooterLeftLabel = backendInfo?.moduleVersion
    ? `Notification version ${backendInfo?.moduleVersion} version hashes: 
    ${frontendCommitHash} - ${getHashCommitValue(
        backendInfo?.commitHash
      )} - updated at 
    ${changeDateFormat(backendInfo?.updatedAt, "mm/dd/yyyy h:mm:ss")}`
    : `Notification version ${frontendModuleVersion} version hashes: 
    ${frontendCommitHash} - N/A - updated at 
    ${frontendUpdatedAt}`;

  return (
    <BackendInfoContext.Provider
      value={{
        backendInfo,
        setBackendInfo,
        rightLabel,
        FooterLeftLabel,
        currentUser,
        scrollAvailabilityValue
      }}
    >
      {children}
    </BackendInfoContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});
export default compose(
  connect(mapStateToProps, {}),
  withApollo
)(BackendInfoWrapper);
