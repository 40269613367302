import React from "react";
import Link from "@mui/material/Link";
import { Auth } from "aws-amplify";
import IconForApp from "../icons/IconForApp";
import { getEnv } from "../utils/helpers/text";

import { OwcIcon } from "@one/react/Components/OwcIcon";
import { OwcDoubleGlobalArea } from "@one/react/Components/OwcDoubleGlobalArea";
import { OwcIconButton } from "@one/react/Components/OwcIconButton";
import { OwcTypography } from "@one/react/Components/OwcTypography";
import { OwcButton } from "@one/react/Components/OwcButton"; // other import way

const currentEnv = getEnv();
const CustomLink = ({ children, disabled, ...props }) => (
  <Link
    onClick={() => {
      if (disabled !== true) {
        if (
          process.env.REACT_APP_COGNITO_LOGIN_ENABLED?.trim()?.toUpperCase() !==
          "TRUE"
        ) {
          Auth.federatedSignIn({
            provider: process.env.REACT_APP_COGNITO_PROVIDER_ID
          });
        } else {
          Auth.federatedSignIn();
        }
      }
    }}
    style={{
      textDecoration: "none"
    }}
    {...props}
  >
    {children}
  </Link>
);

const Appbar = ({ disabled = false }) => {
  const infoInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      <OwcDoubleGlobalArea style={currentEnv ? { top: "42px" } : {}}>
        <div slot="primary" style={{ justifyContent: "space-between" }}>
          <div
            style={{
              paddingLeft: "16px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <IconForApp />
            <OwcTypography style={{ paddingLeft: "16px" }} variant="subtitle1">
              Notification
            </OwcTypography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CustomLink
              disabled={disabled}
              data-testid="link-to-sign-in-on-button"
            >
              <OwcButton
                data-testid="sign-in-button"
                disabled={disabled}
                variant="primary"
                style={{ minWidth: "120px", marginRight: "26px" }}
              >
                Sign in
              </OwcButton>
            </CustomLink>

            <OwcIconButton
              style={{ marginRight: "26px" }}
              flat
              data-testid="info-button"
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
              icon="circle_help"
            />

            <OwcIcon
              type="legacy"
              style={{
                margin: "0 8px",
                color: "var(--one-color-interaction-default-brand-1)",
                width: "48px"
              }}
              name="roche_logo"
            />
          </div>
        </div>
      </OwcDoubleGlobalArea>
      {/*  <AppbarStyled
        data-testid="app-bar-not-auth-main-page"
        style={currentEnv ? { top: "42px" } : {}}
      >
        <RocheTopBar
          title={
            <IconWithText
              iconComponent={IconForApp}
              iconStyle={{ marginRight: ".6rem" }}
            >
              <RocheTypography variant="button">Notification</RocheTypography>
            </IconWithText>
          }
          position="relative"
          rightIcons={[
            <CustomLink
              disabled={disabled}
              data-testid="link-to-sign-in-on-button"
            >
              <SignInButton
                data-testid="sign-in-button"
                disabled={disabled}
                color="primary"
                variant="contained"
                style={{ minWidth: "120px" }}
              >
                Sign in
              </SignInButton>
            </CustomLink>,
            <AppBarInfo>
              <IconButton
                data-testid="info-button"
                onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
                classes={{ root: classes.iconButtonRoot }}
              >
                <i className="one-icons">info</i>
              </IconButton>
            </AppBarInfo>
          ]}
        />
      </AppbarStyled> */}
    </>
  );
};

export default Appbar;
