import React, { useContext, useEffect, useState } from "react";
import { SubscriptionSettingsContext } from "../context/context";
import { withApollo } from "react-apollo";
import { CONFIGURE_NOTIFICATION_THRESHOLDS } from "../../../gql/notifications/mutations";
import { OwcButton } from "@one/react/Components/OwcButton";
import { MicroFrontendContext } from "../../../microFrontend/context";
import { DURATION, GRID_APPLICATION_NAME } from "../../../constants";
import { snackbarService } from "@one/web-components";

const CardActionButton = ({ client }) => {
  const { changes, dispatchAction, forceChange } = useContext(
    SubscriptionSettingsContext
  );
  const { applicationId } = useContext(MicroFrontendContext);

  const [loading, setLoading] = useState(false);
  const handleChanges = async (message) => {
    try {
      setLoading(true);
      const result = await client.mutate({
        mutation: CONFIGURE_NOTIFICATION_THRESHOLDS,
        fetchPolicy: "no-cache",
        variables: {
          arrayOfNotification: changes
        }
      });
      if (
        !result?.data?.configureNotificationThresholds?.includes("code=200")
      ) {
        throw new Error(result);
      }
      dispatchAction({
        type: "confirmChanges"
      });
      setLoading(false);
      snackbarService.show({
        message: message || `Settings saved successfully`,
        type: "success",
        duration: DURATION
      });
    } catch (err) {
      setLoading(false);
      snackbarService.show({
        message: message || `Settings saved successfully`,
        type: "success",
        duration: DURATION
      });
      console.error(err);
    }
  };
  useEffect(() => {
    if (forceChange) {
      handleChanges("Your default subscriptions have been updated!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceChange]);
  // if (!changes.length) {
  //   return null;
  // }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "60px"
      }}
      data-testid="subscriptions-button-actions"
    >
      <div>
        <OwcButton
          variant="secondary"
          data-testid="subscriptions-button-clear-changes"
          onClick={() =>
            dispatchAction({
              type: "clearAll"
            })
          }
          disabled={changes.length === 0 || loading || forceChange}
        >
          Reset changes
        </OwcButton>
      </div>
      <div style={{ display: "flex" }}>
        {applicationId === GRID_APPLICATION_NAME && (
          <OwcButton
            variant="secondary"
            style={{ marginRight: 32 }}
            onClick={() => window.dispatchEvent(new Event("goBack"))}
          >
            Cancel
          </OwcButton>
        )}
        <OwcButton
          onClick={() => handleChanges()}
          disabled={changes.length === 0 || loading}
          data-testid="subscriptions-button-save-changes"
        >
          Save
        </OwcButton>
      </div>
    </div>
  );
};

export default withApollo(CardActionButton);
