import React, { useReducer } from "react";
import { omit } from "underscore";
import { CHANNEL_TYPES, SEVERITY_THRESHOLDS } from "../../../constants";
import { SubscriptionSettingsContext } from "./context";
import { getDiffValues, getInitialValues } from "./helpers";

export const shouldBeDisabled = (fieldType, user) => {
  if (fieldType === CHANNEL_TYPES.EMAIL.value) {
    return !user?.email;
  }
  if (fieldType === CHANNEL_TYPES.SMS.value) {
    return !user?.mobilePhone;
  }
  return false;
};

export const initialState = {
  changes: [],
  initialValues: [],
  data: [],
  forceChange: false,
  emailConfirmed: true
};

const ALL_THRESHOLD_VALUES = [
  SEVERITY_THRESHOLDS.DISABLED.value,
  SEVERITY_THRESHOLDS.LOW.value,
  SEVERITY_THRESHOLDS.MEDIUM.value,
  SEVERITY_THRESHOLDS.HIGH.value
];

export const getNewValue = (
  direction = "",
  currentValue = SEVERITY_THRESHOLDS.DISABLED.value,
  values = ALL_THRESHOLD_VALUES
) => {
  if (!direction) {
    return currentValue;
  }
  if (direction === "toggleValue") {
    if (currentValue !== values[0]) {
      return values[0];
    }
    return values[1];
  }

  const idx = values.findIndex((x) => x === currentValue);
  if (direction === "setPrevValue" && idx > 0) {
    return values[idx - 1];
  }
  if (direction === "setNextValue" && idx < values.length - 1) {
    return values[idx + 1];
  }
  if (direction === "selectSeverity") {
    return currentValue;
  }
  return currentValue;
};

const handleChangeData = (state, action) => {
  const newData = state.data?.map((sub) => {
    if (action.payload?.id !== sub.id) {
      return sub;
    }
    return {
      ...sub,
      [action.payload?.key]: {
        ...sub[action.payload?.key],
        severityThreshold: getNewValue(action.type, action.payload.item)
      }
    };
  });
  return {
    ...state,
    data: newData,
    changes: getDiffValues(state.initialValues, newData)
  };
};

const setDefault = (state, action) => {
  const newData = state.data?.map((sub) => {
    if (
      action.payload?.states?.includes(
        sub[`${sub.id}#${action.payload?.key}`]?.[action.payload?.fieldName]
      )
    ) {
      return {
        ...sub,
        [`${sub.id}#${action.payload?.key}`]: {
          ...sub[`${sub.id}#${action.payload?.key}`],
          [action.payload?.fieldName]: action.payload?.defaultValue
        }
      };
    }
    return sub;
  });
  const changes = getDiffValues(state.initialValues, newData);
  return {
    ...state,
    data: newData,
    changes,
    forceChange: state?.forceChange || !!changes?.length
  };
};

const isEmailConfirmed = (type, values, prevValue) => {
  if (type === "setInitialValues") {
    return values?.some((sub) => sub?.channel === CHANNEL_TYPES.EMAIL.value);
  }
  return (
    prevValue ||
    values?.some((row) =>
      Object.keys(omit(row, ["id", "displayName"])).some(
        (key) =>
          row?.[key]?.channel === CHANNEL_TYPES.EMAIL.value &&
          row?.[key]?.severityThreshold !== SEVERITY_THRESHOLDS.DISABLED.value
      )
    )
  );
};

export function reducer(
  state = initialState,
  action = { type: "", payload: "" }
) {
  switch (action.type) {
    case "setInitialValues": {
      const initialValues = getInitialValues(
        action?.payload?.apps,
        action?.payload?.initialValues
      );
      return {
        ...state,
        initialValues,
        data: initialValues,
        emailConfirmed: isEmailConfirmed(
          action.type,
          action?.payload?.initialValues
        )
      };
    }
    case "selectSeverity":
    case "setPrevValue":
    case "setNextValue":
    case "toggleValue": {
      return handleChangeData(state, action);
    }
    case "setDefault": {
      return setDefault(state, action);
    }
    case "confirmChanges": {
      return {
        ...state,
        initialValues: state.data,
        data: state.data,
        changes: [],
        emailConfirmed: isEmailConfirmed(
          action.type,
          state.data,
          state?.emailConfirmed
        )
      };
    }
    case "clearAll": {
      return {
        ...state,
        initialValues: state.initialValues,
        data: state.initialValues,
        changes: []
      };
    }
    default:
      return state;
  }
}

const SubscriptionSettingsWrapper = ({ children, initialValues }) => {
  const [state, dispatchAction] = useReducer(reducer, {
    ...initialState,
    ...initialValues
  });
  return (
    <SubscriptionSettingsContext.Provider value={{ ...state, dispatchAction }}>
      {children}
    </SubscriptionSettingsContext.Provider>
  );
};

export default SubscriptionSettingsWrapper;
