import React, { useContext } from "react";
import { Footer } from "@digitallab/grid-common-components";
import { BackendInfoContext } from "../backend-info-context/context";

const FooterSection = () => {
  const { FooterLeftLabel } = useContext(BackendInfoContext);
  return (
    <>
      <Footer FooterLink="/info" leftLabel={FooterLeftLabel} />
    </>
  );
};
export default FooterSection;
