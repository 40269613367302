import * as types from "./actionTypes";
import initialState from "./initialState";

export default function userReducer(
  state = initialState,
  action = { type: "", payload: {} }
) {
  if (types.LOAD_USER_INFO === action?.type) {
    return { ...state, ...action?.payload };
  }
  switch(action?.type){
    case types.LOAD_USER_INFO:{
      return { ...state, ...action?.payload };
    }
    case types.UPDATE_USER_MOBILE_PHONE:{
      return { ...state, mobilePhone: action?.payload?.mobilePhone };
    }
    default: {
      return state;
    }
  }
}
