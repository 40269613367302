export const NOTIFICATION_USER = "NotificationUsers";

export const GROUPS_TOKEN_PATH =
  "signInUserSession.accessToken.payload.cognito:groups";

export const ACCESS_TOKEN_PATH = "signInUserSession.idToken.payload";
export const SYNC_PROFILE_FIELDS = {
  givenName: "custom:firstName",
  familyName: "custom:lastName",
  name: "preferred_username",
  phone: "custom:phoneNumber",
  site: "custom:site"
};
export const DEFAULT_SITE = "Rotkreuz";

export const CHANNEL_TYPES = {
  LandingPage: {
    label: "Enable browser notifications",
    value: "LandingPage"
  },
  SMS: {
    label: "SMS",
    value: "SMS"
  },
  EMAIL: {
    label: "Email",
    value: "EMAIL"
  }
};

export const GRID_APPLICATION_NAME = "gridApplication";
export const DURATION = 5000;
export const SEVERITY_THRESHOLDS = {
  NOT_DEFINED: {
    label: "Not defined",
    value: "NOT_DEFINED",
    tooltip: "",
    color: "#BABABA"
  },
  DISABLED: {
    label: "Disabled",
    value: "DISABLED",
    tooltip: "",
    color: "#BABABA"
  },
  LOW: {
    label: "Low",
    value: "LOW",
    tooltip: "Low severity and higher",
    color: "#737373"
  },
  MEDIUM: {
    label: "Medium",
    value: "MEDIUM",
    tooltip: "Medium severity and higher",
    color: "#E26814"
  },
  HIGH: {
    label: "High",
    value: "HIGH",
    tooltip: "High severity",
    color: "#CC0033"
  }
};

export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const DEFAULT_DESCRIPTION_CONSTANT = {
  moduleVersion: "MODULE VERSION",
  moduleName: "APP NAME",
  ciJobId: "CI ID JOB",
  commitHash: "COMMIT HASH",
  updatedAt: "UPDATED AT"
};

export const CONTENT_MESSAGE_WITH_ICON = `By clicking Sync phone number, your old phone number will be overwritten by 
new phone number.`;

export const CONTACT_INFO_MESSAGE_PHONE_NUMBER = `Verify your phone number. Please sync the new phone number if
required`;

export const CONTACT_INFO_MESSAGE_EMAIL = `Don't want to miss notification? Go to your email confirm the
subscription`;
