import React, { useContext } from "react";
import { connect } from "react-redux";
import { SEVERITY_THRESHOLDS } from "../../../constants";
import { SubscriptionSettingsContext } from "../context/context";
import { shouldBeDisabled } from "../context/SubscriptionSettingsWrapper";
import { OwcListItem, OwcSelectDropdown, OwcSlideToggle } from "@one/react";

export const SeveritySelector = ({ item, field, disabled }) => {
  const { dispatchAction } = useContext(SubscriptionSettingsContext);
  const severityThreshold = item?.[`${item.id}#${field}`]?.severityThreshold;
  const handleSelectChange = (e) => {
    let selectedValue = e.detail[0];
    selectedValue = Object.values(SEVERITY_THRESHOLDS).filter((v, index) => {
      return index - 1 === selectedValue;
    });
    dispatchAction({
      type: "selectSeverity",
      payload: {
        key: `${item.id}#${field}`,
        id: item.id,
        item: selectedValue[0].value
      }
    });
  };
  return (
    <OwcSelectDropdown
      label={"Severity"}
      placeholder={"Select an option"}
      style={{ width: "60%" }}
      disabled={item?.displayName === "Equipment repository" ? true : disabled}
      onSelectChange={handleSelectChange}
    >
      {Object.values(SEVERITY_THRESHOLDS).map((threshold) => {
        if (threshold.label === "Not defined") {
          return null;
        }

        return (
          <OwcListItem
            selected={severityThreshold === threshold.value ? true : false}
          >
            {threshold.label}
          </OwcListItem>
        );
      })}
    </OwcSelectDropdown>
  );
};

const mapStateToProps = (store, ownProps) => ({
  disabled: shouldBeDisabled(ownProps.field, store.user)
});

export const SeveritySelectorConnected =
  connect(mapStateToProps)(SeveritySelector);

export const SeverityToggler = ({ item, field }) => {
  const { dispatchAction } = useContext(SubscriptionSettingsContext);
  const severityThreshold = item?.[`${item.id}#${field}`]?.severityThreshold;
  return (
    <OwcSlideToggle
      disableRipple={false}
      checked={severityThreshold !== SEVERITY_THRESHOLDS.DISABLED.value}
      onInputChange={() =>
        dispatchAction({
          type: "toggleValue",
          payload: {
            key: `${item.id}#${field}`,
            id: item.id,
            item: severityThreshold
          }
        })
      }
      style={{ marginLeft: "auto" }}
    />
  );
};
